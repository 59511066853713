import LayoutPublic from '@/layout/LayoutPublic'

export default [{
  path: '/',
  component: LayoutPublic,
  children: [
    {
      path: 'home',
      component: () => import('@components/public/Home')
    },
    {
      path: 'news/:slug',
      component: () => import('@components/public/News')
    }
  ]
}]
