import LayoutLogin from '@/layout/LayoutLogin'

export default [{
  path: '/auth',
  component: LayoutLogin,
  children: [{
    path: 'login',
    component: () => import('@components/auth/Login')
  }, {
    path: 'callback',
    component: () => import('@components/auth/Login')
  }, {
    path: 'register',
    component: () => import('@components/auth/Register')
  }, {
    path: 'password/reset',
    component: () => import('@components/auth/PasswordReset')
  }]
}]
