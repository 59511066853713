import Layout1 from '@/layout/Layout1'

export default [{
  path: '/',
  component: Layout1,
  children: [
    {
      path: 'dashboard',
      component: () => import('@components/user/Dashboard')
    },
    {
      path: 'friends',
      component: () => import('@components/user/Friends')
    },
    {
      path: 'libraries',
      component: () => import('@components/user/Libraries')
    },
    {
      path: 'library/:id',
      component: () => import('@components/user/Library')
    },
    {
      path: 'avatar',
      component: () => import('@components/user/Avatar')
    },
    {
      path: 'profile',
      component: () => import('@components/user/Profile')
    },
    {
      path: 'events',
      component: () => import('@components/user/Events')
    },
    {
      path: 'events/:slug',
      component: () => import('@components/user/Event')
    },
    {
      path: 'news',
      component: () => import('@components/user/News')
    },
    {
      path: 'user/news/:slug',
      component: () => import('@components/user/NewsSingle')
    },
    {
      path: 'notifications',
      component: () => import('@components/user/Notifications')
    },
    {
      path: 'feedback',
      component: () => import('@components/user/Feedback')
    }
  ]
}]
